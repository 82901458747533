// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-blog-index-js": () => import("./../../../src/pages/blog/index.js" /* webpackChunkName: "component---src-pages-blog-index-js" */),
  "component---src-pages-cities-index-js": () => import("./../../../src/pages/cities/index.js" /* webpackChunkName: "component---src-pages-cities-index-js" */),
  "component---src-pages-places-index-js": () => import("./../../../src/pages/places/index.js" /* webpackChunkName: "component---src-pages-places-index-js" */),
  "component---src-templates-about-page-js": () => import("./../../../src/templates/about-page.js" /* webpackChunkName: "component---src-templates-about-page-js" */),
  "component---src-templates-blog-post-js": () => import("./../../../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-city-js": () => import("./../../../src/templates/city.js" /* webpackChunkName: "component---src-templates-city-js" */),
  "component---src-templates-index-page-js": () => import("./../../../src/templates/index-page.js" /* webpackChunkName: "component---src-templates-index-page-js" */),
  "component---src-templates-place-js": () => import("./../../../src/templates/place.js" /* webpackChunkName: "component---src-templates-place-js" */),
  "component---src-templates-single-level-category-page-js": () => import("./../../../src/templates/single-level-category-page.js" /* webpackChunkName: "component---src-templates-single-level-category-page-js" */)
}

